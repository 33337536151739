import React, {useContext, useEffect, useState} from 'react'
import {Routes, Route} from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import {useTranslation} from 'react-i18next'
import {authRoutes, publicRoutes} from "../utils/routes"
import { UserContext } from '../utils/shared/Context'
import { checkAuth } from '../API/userAPI'
import Page404 from '../pages/Page404'
import {PROFILE_ROUTE} from '../utils/consts'
import Profile from '../pages/site/Profile'

const AppRouter = () => {
    const {user} = useContext(UserContext)
    const { t } = useTranslation()
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            setIsLoaded(true)
            return
        }
        checkAuth()
            .then(data => {
                if (!data.user)
                    throw data
                user.login(data)
            })
            .catch(err => console.log(err))
            .finally(() => setIsLoaded(true))
    }, [user])

    if (!isLoaded)
        return <></>

    return (
        <Routes>
            {user.isAuth && authRoutes.map(({path, Component}) => {
                return <Route key={path} path={path} element={<Component/>} exact/>
            })}
            {publicRoutes.map(({path, Component}) => {
                return <Route key={path} path={path} element={<Component/>} exact/>
            })}
            <Route path={PROFILE_ROUTE} element={<Profile/>}/>
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
};

export default observer(AppRouter)
