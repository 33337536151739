import {
  ADD_SCENES_ROUTE,
  ADD_VOUCHERS_ROUTE,
  ADMIN_ROUTE,
  COVERS_ROUTE,
  EDIT_SCENES_ROUTE,
  EDIT_VOUCHERS_ROUTE,
  MAIN_ROUTE,
  ORDERS_ROUTE,
  ORDER_ROUTE,
  SCENES_ROUTE,
  STATS_ROUTE,
  VOUCHERS_ROUTE,
  FAQ_ROUTE,
  DELIVERY_ROUTE,
  CONTACTS_ROUTE,
  WARRANTY_ROUTE,
  POLICY_ROUTE,
  THANKS_ROUTE, DIGITAL_ORDERS_ROUTE, GREETING_ROUTE
} from "./consts"
import Index from './../pages/site/Index'
import Dashboard from './../pages/admin/Dashboard'
import Order from "../pages/site/Order/Order"
import Scenes from "../pages/admin/Scenes"
import Orders from './../pages/admin/Orders'
import EditOrderBook from '../pages/admin/EditOrderBook'
import Stats from './../pages/admin/Stats'
import Scene from "../pages/admin/Scene"
import ProcessOrder from "../pages/admin/ProcessOrder"
import Vouchers from "../pages/admin/Vouchers"
import Voucher from "../pages/admin/Voucher"
import Covers from "../pages/admin/Covers"
import Faq from "../pages/site/Faq"
import Contacts from './../pages/site/Contacts'
import Delivery from './../pages/site/Delivery'
import Warranty from './../pages/site/Warranty'
import PrivacyPolicy from "../pages/site/PrivacyPolicy"
import Thanks from "../pages/Thanks"
import DigitalOrders from '../pages/admin/DigitalOrders'
import ProcessDigitalOrder from '../pages/admin/ProcessDigitalOrder'
import Greeting from '../pages/Greeting'


export const authRoutes = [
  {
    path: ADMIN_ROUTE,
    Component: Dashboard
  },
  {
    path: SCENES_ROUTE,
    Component: Scenes
  },
  {
    path: ADD_SCENES_ROUTE,
    Component: Scene
  },
  {
    path: EDIT_SCENES_ROUTE+"/:id",
    Component: Scene
  },
  {
    path: STATS_ROUTE,
    Component: Stats
  },
  {
    path: ORDERS_ROUTE,
    Component: Orders
  },
  {
    path: DIGITAL_ORDERS_ROUTE,
    Component: DigitalOrders
  },
  {
    path: ORDERS_ROUTE+"/edit/:id",
    Component: EditOrderBook
  },
  {
    path: ORDERS_ROUTE+"/:id",
    Component: ProcessOrder
  },
  {
    path: DIGITAL_ORDERS_ROUTE+"/:id",
    Component: ProcessDigitalOrder
  },
  {
    path: VOUCHERS_ROUTE,
    Component: Vouchers
  },
  {
    path: ADD_VOUCHERS_ROUTE,
    Component: Voucher
  },
  {
    path: EDIT_VOUCHERS_ROUTE+"/:code",
    Component: Voucher
  },
  {
    path: COVERS_ROUTE,
    Component: Covers
  }
]

export const publicRoutes = [
  {
    path: MAIN_ROUTE,
    Component: Index
  },
  {
    path: ORDER_ROUTE,
    Component: Order
  },
  {
    path: THANKS_ROUTE,
    Component: Thanks
  },
  {
    path: FAQ_ROUTE,
    Component: Faq
  },
  {
    path: DELIVERY_ROUTE,
    Component: Delivery
  },
  {
    path: CONTACTS_ROUTE,
    Component: Contacts
  },
  {
    path: WARRANTY_ROUTE,
    Component: Warranty
  },
  {
    path: GREETING_ROUTE+"/:id",
    Component: Greeting
  },
  {
    path: POLICY_ROUTE,
    Component: PrivacyPolicy
  }
]