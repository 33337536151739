export function catchError(err, t) {
    const error = err?.response?.data || err
    if (!error?.errors?.length) {
        window.notify({
            type: 'error',
            message: t(error.message || "Something wrong", {ns: 'errors'})
        })
    } else {
        const errors = error?.errors?.reduce((o, i) => {
            if (!o.find(v => v.msg === i.msg))
                o.push(i)
            return o
        }, [])
        errors.forEach(e => {
            window.notify({
                type: 'error',
                message: t(e.msg || "Something wrong", {ns: 'errors'})
            })
        })
    }
}