import {makeAutoObservable, runInAction} from "mobx"
import storageProvider from "./storageProvider"
import { availableFaceParts } from "../utils/consts"
import getThemeId from "../utils/getThemeId"
import defaultConfig from '../default-config.json'
import appSettings from "./appSettings"
import changeColorsSVG from "../utils/changeColorsSVG"

class OrderState {
    constructor({
        step,
        typeComic,
        hasCouple,
        movedFace,
        selectedTheme,
        selectedSkin,
        selectedFaceParts,
        selectedFacePartsColor,
        lipsPosition,
        selectedCover,
        selectedScenes,
        sequenceScenes
      }) {
      this._availableSteps = ['recipient','characters','cover','pages','my comic','ordering']
      this._availableRecipient = ['husband','wife','friends','mother','father']
      this._availableFaceParts = availableFaceParts || []
      this._currentStep = step || 1
      this._typeComic = typeComic || 'color'
      this._hasCouple = typeof hasCouple !== 'undefined' ? hasCouple : null
      this._movedFace = movedFace || {'man': {}, 'woman': {}}
      this._selectedTheme = selectedTheme || null
      this._selectedSkin = selectedSkin || {'man': 'skin_1','woman': 'skin_1'}
      this._defaultFaceParts = availableFaceParts.reduce((a, v) => ({ ...a, [v]: 'no'}), {})
      this._selectedFaceParts = selectedFaceParts || {'man': this._defaultFaceParts,'woman': this._defaultFaceParts}
      this._selectedFacePartsColor = selectedFacePartsColor || {'man': this._defaultFaceParts,'woman': this._defaultFaceParts}
      this._savedSelectedParts = {}
      this._savedSwapParts = {
        'man': {},
        'woman': {}
      }
      this._lipsPosition = lipsPosition || {
        'man': 'm',
        'woman': 'm'
      }
      this._selectedCover = selectedCover || null
      this._selectedScenes = selectedScenes || {}
      this._sequenceScenes = sequenceScenes || []
      this._orderSettings = {cover: 'hard'}
      this._contactInfo = {}
      this._shipmentCode = 'ua'
      this._shipment = ''
      this._totalPrice = 0
      this._promoCode = ''
      this.isValid = false
      this.isCanDraw = false

      !selectedFaceParts && storageProvider.setItem('selectedFaceParts', this._selectedFaceParts)
      makeAutoObservable(this)
    }

    getOrder(lang) {
      const mainTextType = getThemeId(this._selectedTheme, this._hasCouple)
      const orderedScenes = this._sequenceScenes.map(sceneName => {
        return {
          name: sceneName,
          mainText: this._selectedScenes[sceneName].mainText[mainTextType][lang],
          otherText: this._selectedScenes[sceneName].otherText.map(other => other.text[lang])
        }
      })
      const order = {
        orderSettings: this._orderSettings,
        bookSettings: {
          typeComic: this._typeComic,
          hasCouple: this._hasCouple,
          selectedSkin: this._selectedSkin,
          movedFace: this._movedFace,
          selectedTheme: this._selectedTheme,
          selectedFaceParts: this._selectedFaceParts,
          selectedFacePartsColor: this._selectedFacePartsColor,
          selectedCover: this._selectedCover,
          sequenceScenes: orderedScenes,
        },
        contactInfo: this._contactInfo,
        payment: {
          status: 'new',
          method: 'card'
        },
        shipment: this._shipment,
        status: 'new',
        country: appSettings.country,
        promoCode: this._promoCode,
        total: this._totalPrice
      }
      return order
    }

    async autoSavingSelectedParts() {
      const that = this
      this._savedSelectedParts = JSON.parse(JSON.stringify(this._selectedFaceParts))
      const needLoad = []
      runInAction(() => this.isCanDraw = false)

      for (let sex in that._savedSelectedParts) {
        for (let part in that._savedSelectedParts[sex]) {

          this._savedSelectedParts[sex][part] !== 'no' && needLoad.push(await this.preLoad({
            path: process.env.REACT_APP_FACE_PARTS_PATH + `${this.typeComic}/${sex}/${part}/`,
            name: this._savedSelectedParts[sex][part],
            sex, part,
            saveTo: this._savedSelectedParts[sex],
            end: part
          }))

          if (this._savedSelectedParts[sex][part] !== 'no' && part === 'lips') {
            await this.autoSavingLipsType(sex, part)
          }

          if (this._savedSelectedParts[sex][part] !== 'no' && part === 'eye') {
            await this.autoSavingEyeDirection(sex, part)
          }

          if (this._savedSelectedParts[sex][part] !== 'no' && part === 'eyebrows') {
            await this.autoSavingEyebrowsType(sex, part)
          }

          if (defaultConfig.swapParts[sex][part]) {
            await this.autoSavingSwapParts(sex, part)
          }

        }
      }
      await Promise.all(needLoad)
      runInAction(() => this.isCanDraw = true)
    }

    async preLoad({path, name, sex, part, saveTo, end}) {
      const that = this
      return new Promise(async (resolve) => {
        await fetch(path + `${name}.svg`)
          .then(response => {
            if (response.ok)
              return response.blob()
            return Promise.reject(response)
          })
          .then(async (image) => {
            const blobUrl = (defaultConfig.replaceFacePartsColor.includes(part))
              ? await changeColorsSVG({
                url: URL.createObjectURL(image),
                name: sex + name,
                type: part,
                parts: that._selectedFacePartsColor,
                skins: that._selectedSkin
              })
              : URL.createObjectURL(image)
            saveTo[end] = blobUrl
            resolve()
          })
          .catch(response => {
            console.error(response)
            saveTo[end] = 'no'
          })
      })
    }

    async autoSavingEyeDirection(sex, part, needLoad = []) {
      const isNeedLoad = !!needLoad.length
      this._savedSelectedParts[sex]['eye_direction'] = {}
      for (let direction in {"l":"","r":"","d":"","c":""}) {
        needLoad.push(await this.preLoad({
          path: process.env.REACT_APP_FACE_PARTS_PATH + `${this.typeComic}/${sex}/${part}/`,
          name: this._selectedFaceParts[sex]['eye']+`_${direction}`,
          sex, part,
          saveTo: this._savedSelectedParts[sex]['eye_direction'],
          end: direction
        }))
      }
      isNeedLoad && await Promise.all(needLoad)
    }

    async autoSavingEyebrowsType(sex, part, needLoad = []) {
      const isNeedLoad = !!needLoad.length
      this._savedSelectedParts[sex]['eyebrows_type'] = {}
      for (let type in {"d":"","s":""}) {
        needLoad.push(await this.preLoad({
          path: process.env.REACT_APP_FACE_PARTS_PATH + `${this.typeComic}/${sex}/${part}/`,
          name: this._selectedFaceParts[sex]['eyebrows']+`_${type}`,
          sex, part,
          saveTo: this._savedSelectedParts[sex]['eyebrows_type'],
          end: type
        }))
      }
      isNeedLoad && await Promise.all(needLoad)
    }

    async autoSavingLipsType(sex, part, needLoad = []) {
      const isNeedLoad = !!needLoad.length
      needLoad.push(await this.preLoad({
        path: process.env.REACT_APP_FACE_PARTS_PATH + `${this.typeComic}/${sex}/${part}/`,
        name: this._selectedFaceParts[sex][part] + '_smile',
        sex, part,
        saveTo: this._savedSelectedParts[sex],
        end: 'smile'
      }))
      isNeedLoad && await Promise.all(needLoad)
    }

    async autoSavingSwapParts(sex, part, needLoad = []) {
      const isNeedLoad = !!needLoad.length
      this._savedSwapParts[sex][part] = {}
      defaultConfig.swapParts[sex][part].map(async (swap) => {
        needLoad.push(await this.preLoad({
          path: process.env.REACT_APP_FACE_PARTS_PATH + `${this.typeComic}/${sex}/swap/${part}/`,
          name: (part !== 'lips') ? swap : swap + '_' + this._lipsPosition[sex],
          sex, part,
          saveTo: this._savedSwapParts[sex][part],
          end: swap
        }))
      })
      isNeedLoad && await Promise.all(needLoad)
    }

    setCurrentStep(step) {
      this._currentStep = step
      storageProvider.setItem('currentStep', this._currentStep)
    }

    setTypeComic(type) {
      this._typeComic = type === 'monochrome' ? type : 'color' // enum color | monochrome
      this._selectedFaceParts = {'man': this._defaultFaceParts,'woman': this._defaultFaceParts}
      this._selectedFacePartsColor = {'man': this._defaultFaceParts,'woman': this._defaultFaceParts}
      this._savedSelectedParts = {...this._selectedFaceParts}
      storageProvider.setItem('typeComic', this._typeComic)
      storageProvider.setItem('selectedFaceParts', this._selectedFaceParts)
      storageProvider.setItem('selectedFacePartsColor', this._selectedFacePartsColor)
    }

    setHasCouple(couple) {
      this._hasCouple = (/true/i).test(couple)
      storageProvider.setItem('hasCouple', this._hasCouple)
    }

    setSelectedTheme(theme) {
      this._selectedTheme = theme
      storageProvider.setItem('selectedTheme', theme)
    }

    setSelectedSkin(sex, skin) {
      this._selectedSkin[sex] = skin
      storageProvider.setItem('selectedSkin', this._selectedSkin)
    }

    setMovedFace(sex, part, val) {
        this._movedFace[sex][part] = val
        storageProvider.setItem('movedFace', this._movedFace)
    }

    setSelectedFaceParts(sex, part, val, blobUrl) {
      this._selectedFaceParts[sex][part] = val
      this._savedSelectedParts[sex][part] = blobUrl
      storageProvider.setItem('selectedFaceParts', this._selectedFaceParts)
    }

    setSelectedFacePartsColor(sex, part, color) {
      this._selectedFacePartsColor[sex][part] = color
      storageProvider.setItem('selectedFacePartsColor', this._selectedFacePartsColor)
    }

    setNewSelectedFaceParts(obj) {
      this._selectedFaceParts = obj
    }

    setNewSelectedFacePartsColor(obj) {
      this._selectedFacePartsColor = obj
    }

    setLipsPosition(sex, pos) {
      this._lipsPosition[sex] = pos
      storageProvider.setItem('lipsPosition', this._lipsPosition)
    }

    setSelectedCover(cover) {
      this._selectedCover = cover
      storageProvider.setItem('selectedCover', this._selectedCover)
    }

    setSelectedScenes(name, scene) {
      if (scene) {
        this._selectedScenes = {
          ...this._selectedScenes,
          [name]: {...scene}
        }

      } else {
        delete this._selectedScenes[name]
      }
      storageProvider.setItem('selectedScenes', this._selectedScenes)
    }

    setSequenceScenes(sequenceScenes) {
      this._sequenceScenes = sequenceScenes
      storageProvider.setItem('sequenceScenes', sequenceScenes)
    }

    clearSelectedScenes() {
        this._selectedScenes = {}
        this._sequenceScenes = []
        storageProvider.setItem('selectedScenes', this._selectedScenes)
        storageProvider.setItem('sequenceScenes', this._sequenceScenes)
    }

    setPromoCode(promoCode) {
      this._promoCode = promoCode
    }

    setTotalPrice(totalPrice) {
      this._totalPrice = totalPrice
    }

    setContactInfo(contactInfo) {
      this._contactInfo = contactInfo
    }

    setOrderSettings(orderSettings) {
      this._orderSettings = orderSettings
    }

    setShipment(info) {
      this._shipmentCode = info.code
      this._shipment = info.shipment
    }

    get shipmentCode() {
      return this._shipmentCode
    }

    get currentStep() {
      return this._currentStep
    }

    get availableRecipient() {
      return this._availableRecipient
    }

    get availableSteps() {
      return this._availableSteps
    }

    get availableFaceParts() {
      return this._availableFaceParts
    }

    get typeComic() {
      return this._typeComic
    }

    get hasCouple() {
      return this._hasCouple
    }

    get selectedTheme() {
      return this._selectedTheme
    }

    get selectedSkin() {
      return this._selectedSkin
    }

    get movedFace() {
        return this._movedFace
    }

    get selectedFaceParts() {
      return this._selectedFaceParts
    }

    get selectedFacePartsColor() {
      return this._selectedFacePartsColor
    }

    get savedSwapParts() {
      return this._savedSwapParts
    }

    get savedSelectedParts() {
      return this._savedSelectedParts
    }

    get selectedCover() {
      return this._selectedCover
    }

    get selectedScenes() {
      return this._selectedScenes
    }

    get sequenceScenes() {
      return this._sequenceScenes
    }

    get orderSettings() {
      return this._orderSettings
    }

    get lipsPosition() {
      return this._lipsPosition
    }

    validate() {
        const [_, address] = this._shipment.split('-;-')
        this.isValid = this._orderSettings.cover && address && this._contactInfo.phone &&
            this._contactInfo.name && this._contactInfo.surname
    }
}

export default OrderState