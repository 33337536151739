import React, { useCallback, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { OrderContext } from '../../utils/shared/Context'
import defaultConfig from '../../default-config.json'
import Button from '../../UI/Button/Button'
import './FooterOrder.scss'

function FooterOrder() {
  const { t } = useTranslation()
  const {order} = useContext(OrderContext)
  const [_, reRender] = useState(false)
  const amountScenes = Object.keys(order.selectedScenes || {}).length

  useEffect(() => {
    if (order.currentStep === 4)
      reRender(order.selectedScenes)
  }, [amountScenes])

  const handleClick = useCallback((actionStep) => {
    order.currentStep === 2 && order.autoSavingSelectedParts()
    if (order.currentStep >= 0 && order.currentStep <= order.availableSteps.length) {
      actionStep === 'next'
        ? order.setCurrentStep(order.currentStep + 1)
        : order.setCurrentStep(order.currentStep - 1)
    }
  }, [order])

  const ShowButton = useCallback(() => {
    switch(order.currentStep) {
      case 0:
        return  <Button onClick={() => handleClick('next')}>{t('nextBtn', { ns: 'UI' })}</Button>
      case 1:
        return  order.hasCouple != null ? <Button onClick={() => handleClick('next')}>{t('nextBtn', { ns: 'UI' })}</Button> : ''
      case 2:
        return  <Button onClick={() => handleClick('next')}>{t('chooseCoverBtn', { ns: 'UI' })}</Button>
      case 3:
        return  <Button disabled={(order.selectedCover) ? '' : 'disabled'} onClick={() => handleClick('next')}>{t('goToScenesBtn', { ns: 'UI' })}</Button>
      case 4:
        if (amountScenes < defaultConfig.minPages)
          return  <Button disabled>{t('addMorePageBtn', { ns: 'order' })}</Button>
        if (amountScenes > defaultConfig.maxPages)
          return  <Button disabled>{t('removePageBtn', { ns: 'order' })}</Button>
        return  <Button onClick={() => handleClick('next')}>{t('viewComicBtn', { ns: 'UI' })} ({amountScenes} {t('p.', { ns: 'order' })})</Button>
      case 5:
        if (amountScenes < defaultConfig.minPages)
          return  <Button disabled>{t('addMorePageBtn', { ns: 'order' })}</Button>
        return  <Button onClick={() => handleClick('next')}>{t('orderBtn', { ns: 'UI' })}</Button>
      case 6:
        return  <></>
      default:
        return  <p className='errorCase'>{t('Something wrong', { ns: 'errors' })}</p>
    }
  }, [order.hasCouple, order.selectedCover, amountScenes])


  return (
    <div className='footerOrder'>
      {order.currentStep > 1 && <div className='back' onClick={() => handleClick('prev')}><span className="icon-arrow-l"></span></div>}
      <ShowButton/>
    </div>
  )
}

export default observer(FooterOrder)
