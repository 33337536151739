import React, { useContext } from 'react'
import {addStatistics, saveOrder} from '../../API/orderAPI'
import { UserContext } from '../../utils/shared/Context'
import './OrderInfo.scss'

function OrderInfo({order, setOrder}) {
    const {user} = useContext(UserContext)
    const fullName = `${order.contactInfo?.surname} ${order.contactInfo?.name} ${order.contactInfo?.middle ? order.contactInfo.middle : ''}`
    const shipment = order.shipment.split('-;-')
    async function saveChanges(e) {
        const status = e.target.value.trim()
        const newOrder = {
            edit: true,
            ...order,
            status,
            orderSettings: {
                ...order.orderSettings,
                processing: user.user?.name || user.user.email
            }
        }

        setOrder(newOrder)
        await saveOrder(newOrder)
        if (status === 'done') {
            const sceneList = order.bookSettings.sequenceScenes?.map(s => s.name)
            await addStatistics({cover: order.bookSettings.selectedCover, sceneList})
        }
    }

    return (<>
        <div className='orderInfo'>
            <div className="line">
                <div className="col col-25">Name:</div>
                <div className="col col-75">{fullName || '-'}</div>
            </div>
            <div className="line">
                <div className="col col-25">Phone:</div>
                <div className="col col-75">{order.contactInfo?.phone || '-'}</div>
            </div>
            <div className="line">
                <div className="col col-25">Client:</div>
                <div className="col col-75">{order.client || '-'}</div>
            </div>
        </div>
        <div className='orderInfo mt-15'>
            <div className="line">
                <div className="col col-25">Shipment type:</div>
                <div className="col col-75">
                    <strong>{shipment[0].toUpperCase()}</strong>
                </div>
            </div>
            <div className="line">
                <div className="col col-25">Shipment address:</div>
                <div className="col col-75">{shipment[1]}</div>
            </div>
            <div className="line">
                <div className="col col-25">Payment status:</div>
                <div className="col col-75">
                    <strong>{order.payment?.status || '-'}</strong>
                </div>
            </div>
            <div className="line">
                <div className="col col-25">Payment method:</div>
                <div className="col col-75">{order.payment?.method || '-'}</div>
            </div>
        </div>
        <div className='orderInfo mt-15'>
            <div className="line">
                <div className="col col-25">Order comment:</div>
                <div className="col col-75">{order.orderSettings?.comment || '-'}</div>
            </div>
            <div className="line">
                <div className="col col-25">Need recall:</div>
                <div className="col col-75">{order?.orderSettings?.needCall ? 'Yes' : 'No'}</div>
            </div>
            <div className="line">
                <div className="col col-25">Promo code:</div>
                <div className="col col-75">{order?.promoCode}</div>
            </div>
        </div>
        <div className='orderInfo mt-15'>
            <div className="line">
                <div className="col col-25">Type of cover:</div>
                <div className="col col-75">{order?.orderSettings?.cover || '-'}</div>
            </div>
            <div className="line">
                <div className="col col-25">Gift cover:</div>
                <div className="col col-75">{order?.orderSettings?.gift ? 'Yes' : 'No'}</div>
            </div>
        </div>
        <div className='orderInfo mt-15'>
            <div className="line">
                <div className="col col-25">Order processing:</div>
                <div className="col col-75">{order?.orderSettings?.processing || '-'}</div>
            </div>
            <div className="line">
                <div className="col col-25">Status:</div>
                <div className="col col-75">
                    <select defaultValue={order?.status} onChange={saveChanges}>
                        {["new", "pending", "delivery", "done", "closed"].map(option => <option
                            key={option} value={option}>{option}</option>)}
                    </select>
                </div>
            </div>
        </div>
    </>)
}

export default OrderInfo
