// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/styles/fonts/NyghtSerif-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/styles/fonts/NyghtSerif-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/styles/fonts/NyghtSerif-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Nyght Serif";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("ttf");
  font-weight: normal;
  font-style: normal;
}
.navbarTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 10px;
  border-bottom: 2px solid #EF2768;
}

.navbar {
  border-right: 2px solid #EF2768;
  height: calc(100vh - 60px);
}
.navbar > li {
  display: block;
  width: 100%;
  padding: 1px 10px;
}
.navbar > li.selected {
  background: #EF2768;
}
.navbar > li.selected a {
  color: #fff;
}
.navbar > li a {
  display: block;
  text-decoration: none;
  padding: 10px 0;
  color: #303030;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/constants.scss","webpack://./src/modules/NavBar/NavBar.scss"],"names":[],"mappings":"AAOA;EACI,0BAAA;EACA,2KAAA;EAGA,mBAAA;EACA,kBAAA;ACRJ;AAFA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,gCAAA;AAIF;;AAFA;EACE,+BAAA;EACA,0BAAA;AAKF;AAJE;EACE,cAAA;EACA,WAAA;EACA,iBAAA;AAMJ;AALI;EACE,mBDFI;ACSV;AANM;EACE,WAAA;AAQR;AALI;EACE,cAAA;EACA,qBAAA;EACA,eAAA;EACA,cDLE;ACYR","sourcesContent":["$xs :480px;\n$sm :640px;\n$md : 768px;\n$lg : 1024px;\n$xl: 1280px;\n$xxl: 1536px;\n\n@font-face {\n    font-family: \"Nyght Serif\";\n    src: url('./fonts/NyghtSerif-Light.woff2') format('woff2'),\n         url('./fonts/NyghtSerif-Light.woff') format('woff'),\n         url('./fonts/NyghtSerif-Light.ttf') format('ttf');\n    font-weight: normal;\n    font-style: normal;\n}\n\n$fontFamilyPrimary: 'Nyght Serif', sans-serif;\n$fontFamilySecondary: 'Manrope';\n$primary: #EF2768;\n$primary-hover: #E22160;\n$primary-disabled: #EB83A5;\n$secondary: #DDCABF;\n\n$white: #fff;\n$black: #303030;\n$gray: #777777;\n$error: red;\n$success: forestgreen;\n\n$bg-color-primary: #FFDDF1;\n$bg-color-secondary: #F9E4D7;\n$bg-color-light: #FFFBF4;\n$bg-ellipse-1: #E3C7FF;\n$bg-ellipse-2: #C7DDFF;\n$bg-ellipse-3: #FFD4C7;\n$bg-ellipse-4: #FEC9D3;","@import '../../assets/styles/constants.scss';\n\n\n.navbarTop {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  height: 60px;\n  padding: 10px;\n  border-bottom: 2px solid $primary;\n}\n.navbar {\n  border-right: 2px solid $primary;\n  height: calc(100vh - 60px);\n  > li {\n    display: block;\n    width: 100%;\n    padding: 1px 10px;\n    &.selected {\n      background: $primary;\n      a {\n        color: #fff\n      }\n    }\n    a {\n      display: block;\n      text-decoration: none;\n      padding: 10px 0;\n      color: $black;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
