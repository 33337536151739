import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { jsPDF } from "jspdf"
import { draw, drawFace } from '../../components/Person/Face'
import { getScenes } from '../../API/sceneAPI'
import { OrderContext } from '../../utils/shared/Context'
import { getFacesForDraw } from '../../components/Scene'
import changeColorsSVG from '../../utils/changeColorsSVG'
import Card from '../../components/Card/Card'
import {drawAboveSceneList} from '../../utils/consts'
import {savePdfBook} from '../../API/orderAPI'
import '../../assets/ShantellSans-Regular-normal'
import './BookCreator.scss'

export async function delay(delay) {
  return new Promise(resolve => setTimeout(resolve, delay));
}

function BookCreator({id, settings, setCreateBook, delayTime = 1500 , isPagination = true, children}) {
  const {order} = useContext(OrderContext)
  const canvasPage = useRef()
  const mainText = useRef()
  const [currentPage, setCurrentPage] = useState(settings.sequenceScenes[0])
  const [availableScenes, setAvailableScenes] = useState()
  const [finished, setFinished] = useState(0)
  const scenesPrint = settings.sequenceScenes//.slice(0, 5)
  const pageAmount = scenesPrint.length + (!isPagination ? 1 : 0)

  const quality = 3
  const paddingLeft = 80
  const paddingTop  = 80
  const width = 400 * quality //540 -> 460
  const height = 574 * quality // 775 -> 660
  const heightMainText = 125

  useEffect(() => {
    const list = scenesPrint.reduce((a, v) => {a=a+v.name+';'; return a}, '')
    getScenes({list}).then(async (scenes) => {
      const formattedScenes = {}
      for (const scene of scenes) {
        const {name, ...other} = scene
        formattedScenes[name] = other
      }
      order.setNewSelectedFaceParts(settings.selectedFaceParts)
      order.setNewSelectedFacePartsColor(settings.selectedFacePartsColor)
      await order.autoSavingSelectedParts()
      setAvailableScenes({...formattedScenes})
    })
  }, [])

  useEffect(()=> {
    availableScenes && drawAll()
  }, [availableScenes])

  async function drawCover(context, doc) {
    const contextWidth = doc.internal.pageSize.getWidth()
    const contextHeight = doc.internal.pageSize.getHeight()
    const coverSrc = process.env.REACT_APP_COVERS_PATH + settings.selectedCover + '.webp'

    await doc.addImage(coverSrc, 'webp', 0, 0, contextWidth, contextHeight)
    doc.addPage()
  }

  const drawAll = async () => {
    const doc = new jsPDF({
      unit: 'pt',
      format: "a5"
    })
    const context = canvasPage.current.getContext('2d')
    const centerPage = doc.internal.pageSize.getWidth() / 2
    doc.setFont('ShantellSans-Regular')
    doc.setTextColor('#000000')

    if (!isPagination) await drawCover(context, doc)

    for (const sequenceScene of scenesPrint) {
      doc.setFontSize(22)
      const addedPage = doc.getNumberOfPages()
      setCurrentPage(sequenceScene)
      const {name, ...scene} = sequenceScene
      const swapSrc = process.env.REACT_APP_SCENES_PATH + `${settings.typeComic}/swap/${name}.svg`
      const src = await changeColorsSVG({
        url: process.env.REACT_APP_SCENES_PATH + `${settings.typeComic}/${name}.svg`,
        name: name,
        type: 'skin',
        skins: settings.selectedSkin
      })
      context.clearRect(0, 0, width, height)

      await draw(context, src, width, width * 1.2, [0, 0])
      const drawFaceArr = getFacesForDraw(availableScenes[name].type)
      const scale = width / 1006
      order.isCanDraw && drawFaceArr.map(async (sex) => {
        const widthFace = availableScenes[name].settings['draw_'+sex].width
        const heightFace = availableScenes[name].settings['draw_'+sex].height
        const pos = availableScenes[name].settings['draw_'+sex].pos
        const notDraw = availableScenes[name].settings['draw_'+sex].notDraw
        const swapDraw = availableScenes[name].settings['draw_'+sex].swapDraw
        const angle = availableScenes[name].settings['draw_'+sex].angle
        const eyeDirection = availableScenes[name].settings['draw_'+sex].eyeDirection
        const lipsType = availableScenes[name].settings['draw_'+sex].lipsType
        const eyebrowsType = availableScenes[name].settings['draw_'+sex].eyebrowsType
        await drawFace({
          context, order, sex, width: widthFace, height: heightFace, pos, angle, eyebrowsType, eyeDirection, lipsType, notDraw, swapDraw,
          scaleScene: [scale, 1.233333 * scale]
        })
      })
      //Draw some part of scene above all elements of scene
      drawAboveSceneList.includes(name) && setTimeout(() => {
        draw(context, swapSrc, width, width * 1.2, [0, 0])
      }, delayTime / 2)

      await delay(delayTime)
      const separatedText = textSplitLines((scene.mainText || '').replace(/ {2,}/g, ' ').trim())
      const separatedOtherText = otherTextSplitLines(sequenceScene, scene.otherText)
      const barcode_img = canvasPage.current.toDataURL()

      doc.addImage(barcode_img, 'PNG', paddingLeft / 1.333, paddingTop / 1.333 + 115, (width / quality) / 1.333, (height / quality) / 1.333)
      const offsetTop = 80
      separatedText.slice(0,3).forEach((text, index) => {
        doc.text(text, centerPage, offsetTop + (30 * index), {align: "center"})
      })
      doc.setFontSize(12)
      const offsetTopOtherText = 100 + paddingTop
      const lineHeight = 16
      separatedOtherText.forEach((otherText, key) => {
        const textLinesAmount = otherText.length
        const heightText = availableScenes[sequenceScene.name].otherText[key].size[1]
        const maxLinesAmount = (heightText / lineHeight).toFixed(0)
        const verticalOffset = (maxLinesAmount - textLinesAmount) * lineHeight / 2

        const isBigText = availableScenes[sequenceScene.name]?.otherText[0]?.size[1] >= 250
        const curOffsetTop  = offsetTopOtherText + (verticalOffset + (isBigText ? -25 : 0) +
                              availableScenes[sequenceScene.name].otherText[key].pos[1]) * 0.765
        const curOffsetLeft = (availableScenes[sequenceScene.name].otherText[key].pos[0] +
                               availableScenes[sequenceScene.name].otherText[key].size[0] / 2) * 0.75185
                               + paddingLeft - 22
        otherText.forEach((line, index) => {
          doc.text(line, curOffsetLeft, curOffsetTop + (lineHeight * index), {
            align: "center",
            maxWidth: availableScenes[sequenceScene.name].otherText[key].size[0]
          })
        })
      })
      if (isPagination) {
        doc.setFontSize(4)
        doc.text('-' + addedPage + '-', centerPage, 3, {align: "center"})
      }
      if (addedPage < pageAmount) doc.addPage()

      setFinished(addedPage)
    }

    doc.save(`${id}.pdf`)
    setCreateBook('created')
    if (!isPagination) savePdfBook(id, doc.output('blob'))
  }

  const textSplitLines = useCallback(val => {
    const words = val.split(' ')
    let testComp = document.getElementById(`testMainText`)

    testComp.innerText = ''
    let newLine = '', line = ''
    const separatedText = []
    words.forEach((word, index) => {
      const compareHeight = testComp.offsetHeight
      line += word + ' '
      testComp.innerText = line
      if (compareHeight != testComp.offsetHeight) {
        separatedText.push(newLine.trim())
        line = word + ' '
        testComp.innerText = line
      }
      newLine = line
      if (words.length - 1 === index) separatedText.push(newLine.trim())
    })
    return separatedText
  }, [availableScenes])

  const otherTextSplitLines = useCallback((currentScene, otherTexts = []) => {
    const separatedOtherText = []
    otherTexts.length && otherTexts.forEach((otherText, index) => {
      const words = otherText.replace(/\n/g, ' \n ').replace(/ {2,}/g, ' ').trim().split(' ')
      let testComp = document.getElementById('testOtherText')
      testComp.innerText = ''
      testComp.style.width = availableScenes[currentScene.name].otherText[index].size[0] + 'px'

      let newLine = '', line = ''
      const separatedText = []
      words.forEach((word, index) => {
        const compareHeight = testComp.offsetHeight
        line += word + ' '
        testComp.innerText = line
        if (compareHeight != testComp.offsetHeight || word.includes('/n')) {
          separatedText.push(newLine.trim())
          line = word + ' '
          testComp.innerText = line
        }
        newLine = line
        if (words.length - 1 === index) separatedText.push(newLine.trim())
      })
      separatedOtherText.push(separatedText)
    })
    return separatedOtherText
  }, [availableScenes])

  return (
    <div className={`bookCreator ${!isPagination ? 'hidden' : ''}`}>
      {isPagination && <div>The Book is creating ... {finished} / {pageAmount}</div>}
      {children}
      {availableScenes && <Card dataName={currentPage?.name.toLocaleLowerCase()}>
          <p ref={mainText} id="mainText">
              {currentPage?.mainText || "input some text"}
          </p>
          <div className="sceneBox">
            {currentPage.otherText?.map((context, key) => {
              return <p
                key={`${id}_${key}`}
                id={`otherText_${key}`}
                className="otherText"
                style={{
                  width: availableScenes[currentPage?.name].otherText[key].size[0],
                  height: availableScenes[currentPage?.name].otherText[key].size[1],
                  left: availableScenes[currentPage?.name].otherText[key].pos[0],
                  top: availableScenes[currentPage?.name].otherText[key].pos[1] + 125
                }}
              >
                {context}
              </p>
            })}
            <canvas ref={canvasPage} width={width} height={height} style={{margin: `${heightMainText}px 0 -100px 0`}} crossOrigin="anonymous"/>
          </div>
      </Card>}
      <div id='testMainText'></div>
      <div id='testOtherText'></div>
    </div>
  )
}

export default BookCreator
