import React, { useContext, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { OrderContext } from '../utils/shared/Context'
import { drawAboveSceneList } from '../utils/consts'
import { draw, drawFace } from './Person/Face'
import changeColorsSVG from '../utils/changeColorsSVG'

export function getFacesForDraw(type){
  switch(type){
    case 'man': return ['man']
    case 'woman': return ['woman']
    case 'couple': return ['man','woman']
    default: return []
  }
}

function Scene({sceneName, scene = {}, width, height, pos = [0,0], onlyShape = null, changeSkin = true}) {
  const {order} = useContext(OrderContext)
  const canvas = useRef()
  const typeComic = scene.color || order.typeComic
  let src = process.env.REACT_APP_SCENES_PATH + `${typeComic}/${sceneName}.svg`
  const swapSrc = process.env.REACT_APP_SCENES_PATH + `${typeComic}/swap/${sceneName}.svg`
  const drawFaceArr = getFacesForDraw(scene.type)
  const depends = onlyShape === null ? [src, scene.type, onlyShape] : [src, scene.type, scene.settings, onlyShape]

  useEffect(()=> {
    order.isCanDraw && drawAll()
  }, [...depends, order.isCanDraw])

  const drawAll = async () => {
    if (!scene.type) return null

    const context = canvas.current.getContext('2d')
    context.clearRect(0, 0, width, height)
    if (changeSkin && !!drawFaceArr.length) {
      src = await changeColorsSVG({
        url: src,
        name: sceneName,
        type: 'skin',
        skins: order.selectedSkin
      })
    }

    await draw(context, src, width, height, pos)
    const scale = width / 1006
    drawFaceArr.map(async (sex) => {
      if (!scene?.settings['draw_'+sex])
        return null
      const widthFace = scene.settings['draw_'+sex].width
      const heightFace = scene.settings['draw_'+sex].height
      const pos = scene.settings['draw_'+sex].pos
      const notDraw = scene.settings['draw_'+sex].notDraw
      const swapDraw = scene.settings['draw_'+sex].swapDraw
      const angle = scene.settings['draw_'+sex].angle
      const eyeDirection = scene.settings['draw_'+sex].eyeDirection
      const lipsType = scene.settings['draw_'+sex].lipsType
      const eyebrowsType = scene.settings['draw_'+sex].eyebrowsType
      await drawFace({
        context, order, sex, width: widthFace, height: heightFace, pos, angle, eyebrowsType, eyeDirection, lipsType, notDraw, swapDraw,
        onlyShape, scaleScene: [scale, 1.233333 * scale]
      })
    })
    //Draw some part of scene above all elements of scene
    drawAboveSceneList.includes(sceneName) && setTimeout(() => {
      draw(context, swapSrc, width, height, pos)
    }, 500)
  }

  return (
    <canvas ref={canvas} width={width} height={height}/>
  )
}

export default observer(Scene)
