import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import getLangBySubdomain from './utils/getLangBySubdomain';

const fallbackLng = (localStorage.getItem('lang') != null) ? localStorage.getItem('lang') : getLangBySubdomain(true)
localStorage.setItem('lang', fallbackLng)

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng,
    ns: ['main','order','UI', 'FAQ', 'policy', 'errors'],
  })

export default i18n