import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import Loading from '../../UI/Loading/Loading'
import ConfettiExplosion from 'react-confetti-explosion'
import {delay} from '../../widgets/BookCreator/BookCreator'
import Modal from '../Modal/Modal'
import Comics from './Comics'
import './Greeting.scss'

function GreetingView({currentOrder}) {
  const [isPreview, setIsPreview] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const comicsList = currentOrder?.bookSettings.sequenceScenes.reduce((a, v) => {a.push({}); a.push(v); return a}, [])

  const showView = async () => {
    await delay(2500)
    setIsPreview(true)
    setIsOpen(true)
    await delay(250)
    setIsVisible(true)
  }

  const handleResize = () => {setIsMobile(window.innerWidth < 490)}

  useEffect(() => {
    showView()
    handleResize()
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  if (!currentOrder) return <Loading/>

  return <>
    <Modal active={isOpen} setActive={setIsOpen} effect>
      <StandardGreeting comment={currentOrder?.comment}/>
    </Modal>
    <div className={`greetingBlock ${isPreview ? 'active' : ''}`}>
      {!isPreview && <Loading/>}
      <div className="greetingView">
        {isVisible && <div className="flexCenter">
          <ConfettiExplosion force={0.75} width={920} particleCount={333} duration={2500} zIndex={9999999}/>
          <ConfettiExplosion force={0.75} width={1500} particleCount={333} duration={2500} zIndex={9999999}/>
        </div>}
        {!isMobile && <Comics settings={currentOrder?.bookSettings} list={comicsList} lang={currentOrder?.lang}/>}
        {isMobile && <Comics settings={currentOrder?.bookSettings} list={currentOrder?.bookSettings.sequenceScenes} lang={currentOrder?.lang}/>}
      </div>
    </div>
  </>
}

function StandardGreeting({comment = ''}) {
  const {t} = useTranslation()
  if (!comment)
    return <p className="greetingHeader">
      <span>{t('Congratulation', {ns: 'main'})} &#127881;</span><br/>
      <span>{t('Best gift', {ns: 'main'})} &#128213;</span><br/>
      <span>{t('Lets watch', {ns: 'main'})} &#128293;</span>
    </p>

  const formatted = comment.split('\n')
  return <p className="greetingHeader">{formatted.map((p, i) => <span key={i}>{p}<br/></span>)}</p>
}

export default GreetingView
