import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import './InsideBlock.scss'
import Button from '../../UI/Button/Button'

function InsideBlock() {
  const { t } = useTranslation()
  const [cover , setCover] = useState('cover_059')

  return (
    <div className='insideBlock'>
        <div className='innerWidth paddingPage'>
            <h2>{t('Unique gift in 3 formats', {ns: 'main'})}</h2>
            <div className="promoInside">
                <div className="flexColumn">
                    <h2>{t('Hard cover', {ns: 'order'})}</h2>
                    <p className="mb-15">{t('Hard cover info', {ns: 'order'})}</p>
                    <h2>{t('Paperback', {ns: 'order'})}</h2>
                    <p className="mb-15">{t('Paperback info', {ns: 'order'})}</p>
                    <h2>
                        {t('digital', {ns: 'order'})}
                        <span>+</span>
                        {t('go to', {ns: 'main'})}
                    </h2>
                    <p>{t('link to digital book', {ns: 'main'})}</p>
                    <Button href="greeting/test" className="mt-25">{t('View greeting', {ns: 'main'})}</Button>
                </div>
                <div className="bookPromo">
                    <div className="book">
                        <div className="back">
                            <img src={process.env.REACT_APP_COVERS_PATH + cover + `.webp`} width="100%" height="100%" alt=""/>
                        </div>
                        <div className="page6">
                            <p>{t('promo scene', {ns: 'main'})}</p>
                            <img src={`/assets/images/scenes/scene_1.webp`} alt=""/>
                        </div>
                        <div className="page5"></div>
                        <div className="page4"></div>
                        <div className="page3"></div>
                        <div className="page2"></div>
                        <div className="page1"></div>
                        <div className="front">
                            <img src={process.env.REACT_APP_COVERS_PATH  + cover + `.webp`} width="100%" height="100%" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <p className="slogan">
            <span>{t('Creative gift', {ns: 'main'})}</span>
                <span>{t('from heart', {ns: 'main'})}</span>
            </p>
        </div>
    </div>
  )
}

export default InsideBlock
