import React, {useCallback} from 'react'
import './Card.scss'

function Card({selected, dataName, onClick, className='', controls, buttons, sort, addScene, openScene, removeScene, disable, children}) {
  const handleButton = useCallback(e => {
    const direction = e.target.getAttribute('data-direction') === 'left' ? 'left' : 'right'
    sort(dataName, direction)
  }, [sort])

  return (
    <div
      className={`card ${className} ${selected ? 'selected' : ''} ${disable && !selected ? 'disable' : ''}`.trim()}
      data-name={dataName}
      onClick={onClick}
    >
      {children}
      {controls && <div className="controls" onClick={e => e.stopPropagation()}>
        <a className="add" onClick={e => {addScene(e); e.stopPropagation()}}></a>
        <a className="edit" onClick={e => {openScene(e); e.stopPropagation()}}></a>
        <a className="remove" onClick={e => {removeScene(e); e.stopPropagation()}}></a>
        {buttons && buttons.map(b => <div className={`icon-${b} ${b}`} data-direction={b} key={b} onClick={handleButton}/>)}
      </div>}
      <div className='selectCurrent'></div>
    </div>
  )
}

export default Card
