import React, {useContext, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {observer} from 'mobx-react-lite'
import {OrderContext} from '../../utils/shared/Context'
import getThemeId from '../../utils/getThemeId'
import {getScenes} from '../../API/sceneAPI'
import HTMLFlipBook from 'react-pageflip'
import Card from '../Card/Card'
import Scene from '../Scene'

function Comics ({settings, list, lang}) {
    const {order} = useContext(OrderContext)
    const { t } = useTranslation()
    const [availableScenes, setAvailableScenes] = useState()
    const [scale, setScale] = useState(1)
    const mainTextType = getThemeId(settings.selectedTheme, settings.hasCouple)
    const landscapeWrapper = useRef(null)

    useEffect(() => {
        if (!settings) return

        order.setNewSelectedFaceParts(settings.selectedFaceParts)
        order.setNewSelectedFacePartsColor(settings.selectedFacePartsColor)
        order.autoSavingSelectedParts()

        const list = settings.sequenceScenes.reduce((a, v) => {a=a+v.name+';'; return a}, '')
        getScenes({list}).then(scenes => {
            const formattedScenes = {}
            for (const scene of scenes) {
                const {name, ...other} = scene
                formattedScenes[name] = other
            }
            setAvailableScenes({...formattedScenes})
        })
    }, [settings])

    const handleResize = () => {
        const defaultWidth = window.innerWidth < 490 ? 420 : 880
        const defaultSpace = window.innerWidth < 490 ? 20 : 40
        const wrapperWidth = landscapeWrapper?.current?.offsetWidth || 0

        setTimeout(() => {
            setScale((wrapperWidth - defaultSpace) / defaultWidth)
        }, 100)
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return <div ref={landscapeWrapper} className="bookWrapper">
        <h1 className="heading">
            {t('This story', {ns: 'order'})}
            <span className="py-15">{t('Scroll through', {ns: 'order'})}</span>
        </h1>
        {availableScenes && <HTMLFlipBook
            width={460}
            height={640}
            size="stretch"
            minWidth={230}
            maxWidth={460}
            minHeight={300}
            maxHeight={640}
            maxShadowOpacity={0.5}
            showCover={true}
            mobileScrollSupport={true}
        >
            <div className="cover">
                <img src={process.env.REACT_APP_COVERS_PATH + order.selectedCover + `.webp`} width="100%" height="100%" alt=""/>
            </div>
            {list.map((scene, index) => {
                const sceneName = scene.name
                const selectedScene = availableScenes[sceneName]
                if (!Object.keys(scene).length) {
                    return <div key={index + '_back'} className="back"/>
                }

                return <div key={sceneName} className="front">
                    <Card
                        dataName={sceneName}
                        onClick={() => {}}
                    >
                        <p className="mainText" style={{fontSize: `${Math.floor(26.6 * scale)}px`}}>
                            <span>{selectedScene?.mainText?.[mainTextType][lang]}</span>
                        </p>
                        <DrawPages sceneName={sceneName} selectedScene={selectedScene} scale={scale} lang={lang}/>
                    </Card>
                </div>
            })}
            <div className="coverBack">
                <div>
                    <p>{t('Hello', {ns: 'order'})}!</p><br/>
                    <p>{t('Uh', {ns: 'order'})}</p><br/>
                    <p>{t('We hope', {ns: 'order'})}</p>
                </div>
            </div>
        </HTMLFlipBook>}
    </div>
}

function DrawPages({sceneName, selectedScene, scale, lang}) {
    return <div className="sceneBox">
        {selectedScene?.otherText?.map(key => {
            const width = key.size[0] * 100 / 400 + '%'
            const height = key.size[1] * 100 / 480 + '%'
            const left = key.pos[0] * 100 / 400 + '%'
            const top = key.pos[1] * 100 / 480 + '%'

            return <p
                key={sceneName + key.text[lang]}
                className="otherText"
                style={{
                    fontSize: `${16 * scale}px`,
                    width,
                    height,
                    left,
                    top
                }}
            >
                {key.text[lang]}
            </p>
        })}
        <Scene
            sceneName={sceneName}
            scene={selectedScene}
            width={400}
            height={480}
            pos={[0, 0]}
        />
    </div>
}

export default observer(Comics)