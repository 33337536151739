import React, {useCallback, useEffect, useState} from 'react'
import LayoutAdmin from '../../modules/LayoutAdmin/LayoutAdmin'
import { useNavigate, useParams } from 'react-router-dom'
import { ORDERS_ROUTE } from '../../utils/consts'
import {getOrder, saveOrder} from '../../API/orderAPI'
import {getScenes} from '../../API/sceneAPI'
import Card from '../../components/Card/Card'
import '../../components/OrderSteps/Step_5/Step_5.scss'
import Button from '../../UI/Button/Button'

function EditOrderBook() {
  const {id} = useParams()
  const [order, setOrder] = useState(null)
  const [availableScenes, setAvailableScenes] = useState()
  const navigate = useNavigate()
  const goBack = () => navigate('/admin/orders/' + id)

  useEffect(() => {
    getOrder(id).then(order => {
      setOrder(order)
    }).catch(err => console.error(err))
  }, [])

  useEffect(() => {
    if (!order?.bookSettings)
      return
    const list = order.bookSettings.sequenceScenes.reduce((a, v) => {a=a+v.name+';'; return a}, '')
    getScenes({list}).then(async (scenes) => {
      const formattedScenes = {}
      for (const scene of scenes) {
        const {name, ...other} = scene
        formattedScenes[name] = other
      }
      setAvailableScenes({...formattedScenes})
    })
  }, [order])

  const saveEditedOrder = useCallback(async () => {
    await saveOrder({
      edit: true,
      ...order,
    })
  }, [order])

  const changeMainText = useCallback((e, sceneName, index) => {
    const val = e.target.value.trim()
    const newSequence = order.bookSettings.sequenceScenes.map(s => {
      if (s.name === sceneName) {
        if (index || index === 0) {
          s.otherText[index] = val
        } else {
          s.mainText = val
        }
      }
      return s
    })
    setOrder(prev => ({
      edit: true,
      ...prev,
      bookSettings: {
        ...prev.bookSettings,
        sequenceScenes: newSequence
      }
    }))
  }, [order])

  const removeScene = useCallback(async(sceneName) => {
    const newSequence = order?.bookSettings.sequenceScenes.filter(s => s.name !== sceneName)
    setOrder(prev => ({
      edit: true,
      ...prev,
      bookSettings: {
        ...prev.bookSettings,
        sequenceScenes: newSequence
      }
    }))
  }, [order])

  return (
    <LayoutAdmin currentPath={ORDERS_ROUTE}>
      <h1 className='title'>
        <div style={{cursor: 'pointer'}} onClick={goBack}>{'<'}&nbsp;</div>
        Edit order
        <Button style={{marginLeft: 'auto'}} onClick={saveEditedOrder}>Save</Button>
      </h1>
      {availableScenes && order && <>
        <div className='sortScenes editable'>
          {order.bookSettings.sequenceScenes.map(scene => {
            const sceneName = scene.name
            const src = process.env.REACT_APP_SCENES_PATH + `color/${sceneName}.svg`
            return <div
                key={sceneName}
                className='sortItem'
                style={{marginBottom: '15px'}}
            >
              <Card dataName={sceneName.toLocaleLowerCase()}>
                <textarea onBlur={e => changeMainText(e, sceneName)} className="mainText" defaultValue={scene?.mainText}/>
                <div className="controls">
                  <a className="remove" onClick={() => removeScene(sceneName)}></a>
                </div>
                <div className="sceneBox">
                  {availableScenes[sceneName]?.otherText?.map((key, i) => {
                    return <textarea
                        onBlur={e => changeMainText(e, sceneName, i)}
                        key={sceneName + scene.otherText[i]}
                        className="otherText"
                        style={{
                          padding: '0',
                          width: key.size[0] / 2,
                          height: key.size[1] / 2,
                          left: key.pos[0] / 2,
                          top: key.pos[1] / 2
                        }}
                        defaultValue={scene.otherText[i]}
                    />
                  })}
                  <img src={src} alt=""/>
                </div>
              </Card>
            </div>
          })}
        </div>
      </>}
    </LayoutAdmin>
  )
}

export default EditOrderBook
