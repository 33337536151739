import React, { useContext, useEffect, useState } from 'react'
import LayoutAdmin from '../../modules/LayoutAdmin/LayoutAdmin'
import { useNavigate, useParams } from 'react-router-dom'
import { ORDERS_ROUTE } from '../../utils/consts'
import BookCreator from '../../widgets/BookCreator/BookCreator'
import { getOrder } from '../../API/orderAPI'
import Button from './../../UI/Button/Button';
import Modal from '../../components/Modal/Modal'
import OrderInfo from '../../components/OrderInfo/OrderInfo'
import OrderState from '../../store/OrderState'
import {OrderContext} from '../../utils/shared/Context'

function ProcessOrder() {
  const {id} = useParams()
  const [currentOrder, setCurrentOrder] = useState(null)
  const [createBook, setCreateBook] = useState(null)
  const navigate = useNavigate()
  const goBack = () => navigate('/admin/orders/')
  const printDirectory = currentOrder?.orderSettings?.cover === 'hard' ? 'print/' : 'print_a4/'

  useEffect(() => {
    getOrder(id).then(data => {
      console.log(data)
      setCurrentOrder(data)
      data.status === 'pending' && setCreateBook('ready')
    }).catch(err => console.error(err))
  }, [])

  function handleCreateBook() {
    setCreateBook('process')
  }

  function handleEditBook() {
    navigate('/admin/orders/edit/' + id)
  }

  return (
    <LayoutAdmin currentPath={ORDERS_ROUTE}>
      <h1 className='title'>
        <div style={{cursor: 'pointer'}} onClick={goBack}>{'<'}&nbsp;</div>
        Processing order ID: {id}
      </h1>
      {currentOrder && <>
        <div className="row">
          <div className="col col-75">
            <OrderInfo order={currentOrder} setOrder={setCurrentOrder}/>
          </div>
          <div className="col col-25">
            {currentOrder.status !== 'new' && <img src={process.env.REACT_APP_COVERS_PATH + currentOrder.bookSettings.selectedCover + `.webp`} className='mb-15' alt=''/>}
            {currentOrder.status !== 'new' && <Button href={process.env.REACT_APP_COVERS_PATH + printDirectory + currentOrder.bookSettings.selectedCover + `.pdf`} download>Get cover</Button>}
            <Button onClick={handleEditBook} className="mt-15" disabled={createBook === 'process'}>Edit book</Button>
            <Button onClick={handleCreateBook} className="mt-15" disabled={createBook === 'process'}>Create book</Button>
          </div>
        </div>
        <OrderContext.Provider value={{
          order: new OrderState(currentOrder.bookSettings)}}>
        {createBook === 'process' && <Modal active setActive={() => setCreateBook('ready')}>
            <BookCreator id={id} settings={currentOrder.bookSettings} setCreateBook={setCreateBook}/>
        </Modal>}
        </OrderContext.Provider>
      </>}
    </LayoutAdmin>
  )
}

export default ProcessOrder
