import {useCallback, useContext} from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { OrderContext } from '../../utils/shared/Context'
import './ProgressBar.scss'

function ProgressBar() {
  const {order} = useContext(OrderContext)
  const { t } = useTranslation()

  const moveStep = useCallback(e => {
      e.stopPropagation()
      const step = e.target.getAttribute('data-step') || 1
      if (step < order.currentStep) order.setCurrentStep(parseInt(step, 10))
  }, [order.currentStep])

  return (
    <div className='progressContainer'>
      <ul className={`box-item_bg--${order.currentStep}`}>
        {
          order.availableSteps.map((step,key) => {
            return  <li key={key} className={(key < order.currentStep) ? 'box-item_list' : ''}
                        onClick={moveStep} data-step={key + 1}>
                      <span onClick={moveStep} data-step={key + 1}>{t(step, { ns: 'order' })}</span>
                    </li>
          })
        }
      </ul>
    </div>
  )
}

export default observer(ProgressBar)
