import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { checkPayment, saveOrder, saveDigitalOrder } from '../../API/orderAPI'
import { getPaymentData, purchaseOrder } from '../../store/paymentProvider'
import {OrderContext, UserContext} from '../../utils/shared/Context'
import PaymentStatusNew from './PaymentStatusNew'
import Button from '../../UI/Button/Button'
import BookCreator from '../../widgets/BookCreator/BookCreator'
import OrderState from '../../store/OrderState'
import {observer} from 'mobx-react-lite'

function PaymentStatusView({order, updateOrder, digital}) {
  const { t } = useTranslation()
  const {user} = useContext(UserContext)
  const status = order.payment?.status
  const checkPeriodical = useRef()

  const changeOrder = useCallback(paymentStatus => {
    const status = paymentStatus === 'Approved' ? 'done' : 'pending'
    if (status === order.payment.status)
      return null

    const changedOrder = {
      ...order,
      edit: true,
      payment: {...order.payment, status}
    }

    if (digital)
      return saveDigitalOrder(changedOrder)
          .then(res => updateOrder(changedOrder))
          .catch(err => console.error(err))

    saveOrder(changedOrder)
      .then(res => {
        updateOrder(changedOrder)
      })
      .catch(err => console.error(err))
    //reset client book history
    if (status === 'done') {
      localStorage.setItem('order', '')
    }
  }, [order])

  const buildPaymentInvoice = useCallback(async (order) => {
    const invoice = await purchaseOrder(order, user.lang)
    if (!invoice.invoiceUrl)
      return null

    const changedOrder = {
      ...order,
      edit: true,
      payment: {
        method: 'card',
        status: 'pending',
        invoiceUrl: invoice.invoiceUrl,
        qrCode: invoice.qrCode
      }
    }

    if (digital) {
      saveDigitalOrder(changedOrder)
          .then(res => updateOrder(changedOrder))
          .catch(err => console.error(err))
    } else {
      saveOrder(changedOrder)
          .then(res => updateOrder(changedOrder))
          .catch(err => console.error(err))
    }

    window.location.replace(invoice.invoiceUrl)
  }, [user])

  const checkPaymentStatus = useCallback(() => {
    const data = getPaymentData({order, type: "check"})
    checkPayment(order._id, data).then(payment => {
      if (!order.payment.invoiceUrl)
        return buildPaymentInvoice(order)

      changeOrder(payment.transactionStatus)
    }).catch(err => console.error(err))
  }, [order])

  useEffect(() => {
    if (['new', 'pending'].includes(order?.payment.status))
      checkPaymentStatus()
    if (order?.payment.status === 'pending') {
      checkPeriodical.current = setInterval(() => checkPaymentStatus(), 5000)
    }
    return () => clearInterval(checkPeriodical.current)
  }, [order?.payment.status, checkPeriodical])

  switch (status) {
    case 'new': return <PaymentStatusNew order={order} digital={digital}/>
    case 'pending': return <PaymentStatusNew order={order} digital={digital} pending/>
    case 'done':
    default: return <>
      <h1>{t('Thanks title', { ns: 'main' })}</h1><br/><br/>
      <div style={{margin: '0 auto', maxWidth: '750px'}}>
        {t('order number', {ns: 'main'})}:<br/><br/>
        <strong className="highlightBlock">#{order._id}</strong>
        <br/><br/>
        {digital ? <DigitalView order={order} digital={digital}/> : <>
          {t('about order', {ns: 'main'})}
          <br/><br/>
          {t('order ready', {ns: 'main'})}
          <br/><br/>
          {t('check order', {ns: 'main'})}
        </>}
      </div>
    </>
  }
}

const DigitalView = ({order}) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState(order.status)
  const [progressValue, setProgressValue] = useState(0)
  const greetingLink = window.location.origin + '/greeting/' + order._id
  const downloadLink = process.env.REACT_APP_SERVER_API_URL + 'api/book/' + order._id
  const progressTime = 1500
  const limit = (order.bookSettings.sequenceScenes?.length + 3) * progressTime

  useEffect(() => {
    if (!order.portrait) {
      const processing = setInterval(updateProcess, progressTime)

      function updateProcess() {
        if (progressValue >= limit) return clearInterval(processing)
        setProgressValue(prev => (prev + progressTime))
      }

      return () => {clearInterval(processing)}
    }
  }, [])

  const downloadPdf = useCallback(() => {
    fetch(downloadLink, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${order._id}.pdf`)
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
        })
  }, [downloadLink])

  const updateStatus = useCallback(async (status) => {
    if (status !== 'created') return
    await saveDigitalOrder({...order, edit: true, status: 'done'})
    setStatus('done')
  }, [order])

  if(order.portrait)
    return <p>{t('about digital order', {ns: 'main'})}</p>

  if(status === 'done')
    return <>
      <p>{t('link to digital book', {ns: 'main'})} "{t('go to', {ns: 'main'})}".</p>
      <p>{t('advice to digital book', {ns: 'main'})}</p>
      <div className="flexCenter space-center">
        <Button href={greetingLink} action className="mt-25 mb-15" style={{display: 'inline-block'}}>
          {t('go to', {ns: 'main'})}
        </Button>
        <Button onClick={downloadPdf} className="mt-25 mb-15" style={{display: 'inline-block'}}>
          {t('downloadBtn', {ns: 'UI'})} .pdf
        </Button>
      </div>
      <p style={{fontSize: '0.8em'}}>{t('limit digital book', {ns: 'main'})}</p><br/>
    </>

  return <OrderContext.Provider value={{
    order: new OrderState(order.bookSettings)
  }}>
  <p>{t('about auto digital order', {ns: 'main'})}</p>
    {(!order.portrait && status === 'new') && <div className="pt-5">
      <div className="creating">
        <div style={{width: Math.min(progressValue / limit * 100 , 100) + '%'}}/>
      </div>
      <BookCreator id={order._id} settings={order.bookSettings} delayTime={progressTime} setCreateBook={updateStatus} isPagination={false}/>
    </div>}
  </OrderContext.Provider>
}

export default observer(PaymentStatusView)