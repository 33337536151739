import React from 'react'
import './Button.scss'

function Button({href = null, className, disabled, onClick, action, children, style, download}) {
  if (href)
    return (
      <a
        href={href}
        download={download}
        className={`btn ${className ? className : ''}`}
        target={action ? "_blank" : "_self"}
        style={{...style, pointerEvents: disabled ? 'none' : 'initial'}}
      >
        {children}
      </a>
    )
  return (
    <div
      data-action={action}
      className={`btn ${className ? className : ''} ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      style={{...style, pointerEvents: disabled ? 'none' : 'initial'}}
    >
      {children}
    </div>
  )
}

export default Button
