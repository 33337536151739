import React, {useCallback, useContext, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { ORDER_ROUTE } from '../../utils/consts'
import { UserContext } from '../../utils/shared/Context'
import { getOrders } from '../../API/orderAPI'
import { formatDate } from '../../utils/shared/formatDate'
import Button from '../../UI/Button/Button'
import './UserOrders.scss'
import {catchError} from '../../utils/shared/catchError'

function UserOrders() {
    const { t } = useTranslation()
    const {user} = useContext(UserContext)
    const [orders, setOrders] = useState([])

    useEffect(() => {
        // TODO: Show order by IP too (getIP())
        getOrders({client: user.user.id})
            .then(async (orderList) => {
                const digitalOrders = await getOrders({client: user.user.id}, true)
                setOrders([...orderList, ...digitalOrders].sort(function(a,b){
                    return new Date(b.createdAt) - new Date(a.createdAt)
                }))
            })
            .catch(err => catchError(err, t))
    }, [])

    const checkStatus = useCallback(order => {
        if (order.payment.status === 'pending')
            return <>
                {t(`status_payment_pending`, { ns: 'main' })}&nbsp;
                (<a href={order.payment.invoiceUrl} className='text_a'>{t('paymentBtn', { ns: 'UI' })}</a>)
            </>
        return t(`status_${order.status}`, { ns: 'main' })
    }, [])

    return (
        <div className="userOrders contentBlock">
            <h3>{t('Your orders', { ns: 'main' })}</h3>
            <div className="orderItems">{orders.length ?
                orders.map(order => <div key={order._id} className='orderItem'>
                    <img src={process.env.REACT_APP_COVERS_PATH + order.bookSettings.selectedCover + `.webp`} alt=""/>
                    <div className="orderBlock">
                        <div className="line">
                            <div className="th">{t('Order number', {ns: 'main'})}:</div>
                            <div><strong>#{order._id}</strong></div>
                        </div>
                        <div className="line">
                            <div className="th">{t('Order status', {ns: 'main'})}:</div>
                            <div><strong>{checkStatus(order)}</strong></div>
                        </div>
                        {order.contactInfo ?
                            <div className="line">
                                <div className="th">{t('Recipient', {ns: 'main'})}:</div>
                                <div><strong>{order.contactInfo.surname} {order.contactInfo.name}</strong></div>
                            </div> : <div className="line">
                                <div className="th">{t('go to', {ns: 'main'})}:</div>
                                <div><a href={`/greeting/${order._id}`} target="_blank" className="text_a">
                                    <strong>{t('View greeting', {ns: 'main'})}</strong>
                                </a></div>
                            </div>}
                        <div className="line">
                            <div className="th">{t('Order date', {ns: 'main'})}:</div>
                            <div><strong>{formatDate(order.createdAt)}</strong></div>
                        </div>
                        <div className="line">
                            <div className="th">{t('Total price', {ns: 'main'})}:</div>
                            <div><strong>{order.total.price} {order.total.currency}</strong></div>
                        </div>
                    </div>
                </div>) :
                <div className='noData'>
                    <p>{t('No orders', {ns: 'main'})}</p>
                    <Button href={ORDER_ROUTE}>{t('createStoryBtn', {ns: 'UI'})}</Button>
                </div>
            }</div>
        </div>
    )
}

export default UserOrders
