import React, { useEffect, useState } from 'react'
import LayoutAdmin from '../../modules/LayoutAdmin/LayoutAdmin'
import { useNavigate, useParams } from 'react-router-dom'
import {DIGITAL_ORDERS_ROUTE, ORDERS_ROUTE} from '../../utils/consts'
import { getOrder, saveDigitalOrder, saveOrder} from '../../API/orderAPI'

function ProcessDigitalOrder() {
  const {id} = useParams()
  const [currentOrder, setCurrentOrder] = useState(null)
  const navigate = useNavigate()
  const goBack = () => navigate('/admin/orders/digital')

  useEffect(() => {
    getOrder(id, true).then(data => {
      setCurrentOrder(data)
    }).catch(err => console.error(err))
  }, [])

  async function saveChanges(e) {
    const status = e.target.value.trim()
    const newOrder = {
      edit: true,
      ...currentOrder,
      status
    }

    setCurrentOrder(newOrder)
    await saveDigitalOrder(newOrder)
  }

  return (
    <LayoutAdmin currentPath={DIGITAL_ORDERS_ROUTE}>
      <h1 className='title'>
        <div style={{cursor: 'pointer'}} onClick={goBack}>{'<'}&nbsp;</div>
        Processing digital order ID: {id}
      </h1>
      {currentOrder && <div className="row">
        <div className="col col-75">
          <div className='orderInfo mb-15'>
            <div className="line">
              <div className="col col-25">Payment status:</div>
              <div className="col col-75">
                <strong>{currentOrder.payment?.status || '-'}</strong>
              </div>
            </div>
            <div className="line">
              <div className="col col-25">Payment method:</div>
              <div className="col col-75">{currentOrder.payment?.method || '-'}</div>
            </div>
            <div className="line">
              <div className="col col-25">Price ({currentOrder.portrait === 2 ? '2 portraits' : '1 portrait'}):</div>
              <div className="col col-75">{currentOrder.total.price} {currentOrder.total.currency}</div>
            </div>
          </div>
          <div className="orderInfo">
            <div className="line">
              <div className="col col-25">Phone:</div>
              <div className="col col-75">{currentOrder?.phone || '-'}</div>
            </div>
            <div className="line">
              <div className="col col-25">Order comment:</div>
              <div className="col col-75">{currentOrder?.comment || '-'}</div>
            </div>
          </div>
          <div className='orderInfo mt-15'>
            <div className="line">
              <div className="col col-25">Status:</div>
              <div className="col col-75">
                <select defaultValue={currentOrder?.status} onChange={saveChanges}>
                  {["new", "pending", "done", "closed"].map(option => <option
                      key={option} value={option}>{option}</option>)}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </LayoutAdmin>
  )
}

export default ProcessDigitalOrder
