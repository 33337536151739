import React from 'react'
import ContactInfo from '../../components/ContactInfo/ContactInfo'
import UserOrders from '../../components/UserOrders/UserOrders'
import UserPromoCode from '../../components/UserPromoCode/UserPromoCode'
import PartnerPromoCode from '../../components/UserPromoCode/PartnerPromoCode'
import './ProfileBlock.scss'

function ProfileBlock() {
  return (
    <div className='pageBlock'>
      <div className='innerWidth paddingPage'>
        <UserPromoCode/>
        <PartnerPromoCode/>
        <ContactInfo/>
        <UserOrders/>
      </div>
    </div>
  )
}

export default ProfileBlock