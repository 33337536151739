import { useTranslation } from 'react-i18next'
import LayoutDefault from '../../modules/LayoutDefault'

const Faq = () => {
  const { t } = useTranslation()

  return (
    <LayoutDefault>
      <div className='pageBlock'>
        <div className='innerWidth paddingPage'>
          <div className="contentBlock">
            <h1 className="h3">{t('FAQ title', { ns: 'FAQ' })}</h1>
            <div className="mt-25">
              <strong>{t('Q1 title', { ns: 'FAQ' })}</strong>
              <p className="pt-5">{t('Q1 text', { ns: 'FAQ' })}</p>
            </div>
            <div className="mt-25">
              <strong>{t('Q2 title', { ns: 'FAQ' })}</strong><br />
              <p className="pt-5">{t('Q2 text', {ns: 'FAQ'})}</p>
            </div>
            <div className="mt-25">
              <strong>{t('Q3 title', { ns: 'FAQ' })}</strong><br />
              <p className="pt-5">{t('Q3 text', {ns: 'FAQ'})}</p>
            </div>
            <div className="mt-25">
              <strong>{t('Q4 title', { ns: 'FAQ' })}</strong><br />
              <p className="pt-5">{t('Q4 text', {ns: 'FAQ'})}</p>
            </div>
            <div className="mt-25">
              <strong>{t('Q5 title', { ns: 'FAQ' })}</strong><br />
              <p className="pt-5">
                {t('Q5 text', {ns: 'FAQ'})}: <a href="greeting/test" className="text_a">{t('View greeting', {ns: 'main'})}</a>
              </p>
            </div>
            <div className="mt-25">
              <strong>{t('Q6 title', { ns: 'FAQ' })}</strong><br />
              <p className="pt-5">{t('Q6 text', {ns: 'FAQ'})}</p>
            </div>
            <div className="mt-25">
              <strong>{t('Q7 title', { ns: 'FAQ' })}</strong><br />
              <p className="pt-5">{t('Q7 text', {ns: 'FAQ'})}</p>
            </div>
            <div className="mt-25">
              <strong>{t('Q8 title', { ns: 'FAQ' })}</strong><br />
              <p className="pt-5">{t('Q8 text', {ns: 'FAQ'})}</p>
            </div>
            <div className="mt-25">
              <strong>{t('Q9 title', { ns: 'FAQ' })}</strong><br />
              <p className="pt-5">{t('Q9 text', {ns: 'FAQ'})}</p>
            </div>
            <div className="mt-25">
              <strong>{t('Q10 title', { ns: 'FAQ' })}</strong><br />
              <p className="pt-5">{t('Q10 text', {ns: 'FAQ'})}</p>
            </div>
          </div>
        </div>
      </div>
    </LayoutDefault>
  )
}

export default Faq