import React, {useCallback, useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {UserContext} from '../../utils/shared/Context'
import {updateInfo} from '../../API/userAPI'
import Button from '../../UI/Button/Button'
import Modal from '../Modal/Modal'
import './UserPromoCode.scss'

function PartnerPromoCode() {
    const { t } = useTranslation()
    const {user} = useContext(UserContext)
    const [comment, setComment] = useState('')
    const [isPending, setIsPending] = useState(Array.isArray(user.user.voucher))
    const [isOpen, setIsOpen] = useState(false)

    const toggleModal = useCallback(() => setIsOpen(prev => !prev), [])
    const changeComment = useCallback(e => setComment(e.target.value), [])

    const sendRequest = useCallback(() => {
        setIsPending(true)
        updateInfo({...user.user, comment, voucher: []}).then(() => {
            notify({type: 'success', message: t('Thanks for request', {ns: 'main'})})
        }).finally(() => toggleModal())
    }, [comment])

    return <div>
        <div className="userPromoCode contentBlock">
            <h3>{t('Partner promocode', {ns: 'main'})}</h3>
            <p className="py-15 mt-15 mb-25">
                {t('Send request info', {ns: 'main'})}<br/><br/>
                - {t('Variant 1', {ns: 'main'})}<br/>
                - {t('Variant 2', {ns: 'main'})}<br/>
                - {t('Variant 3', {ns: 'main'})}<br/><br/>
                {t('Send request connection', {ns: 'main'})}
            </p>
            <Button block onClick={toggleModal}>
                {t('sendRequestBtn', { ns: 'UI' })}
            </Button>
        </div>
        <Modal active={isOpen} setActive={toggleModal}>
            <div className="partnerModalView">
                <h3>{t('Info about you', {ns: 'main'})}</h3>
                <textarea placeholder={t('Comment', {ns: 'order'})} className="mb-25 mt-25" value={comment} onChange={changeComment}/>
                <Button onClick={sendRequest} disabled={isPending} style={{marginLeft: 'auto'}}>{t('sendRequestBtn', {ns: 'UI'})}</Button>
            </div>
        </Modal>
    </div>
}

export default PartnerPromoCode