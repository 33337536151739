import { useContext, useEffect, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { observer } from 'mobx-react-lite'
import {availableFaceParts, availableMoveFaceParts} from '../../utils/consts'
import {OrderContext, LoadFacePartsContext} from '../../utils/shared/Context'
import changeColorsSVG from '../../utils/changeColorsSVG'

export async function drawFace({
  context, order, sex, width, height, pos = [0,0], angle, scaleScene = [1,1],
  eyebrowsType = '', eyeDirection = '', lipsType ='',
  notDraw = [], swapDraw = {}, onlyShape = false
}) {
  const widthDraw = width * scaleScene[0]
  const heightDraw = height * scaleScene[1]
  const posDraw = [pos[0] * scaleScene[0], pos[1] * scaleScene[0]]
  if (onlyShape) {
    await draw(context, `/assets/images/persons/shape_face_${sex}.svg`, widthDraw, heightDraw, posDraw, angle)
  } else {
    for (const part of availableFaceParts) {
      const name = order.selectedFaceParts[sex][part]
      if (name !== 'no' && !notDraw.includes(part) && !Object.keys(swapDraw).includes(part)) {
        let facePartUrl = order.savedSelectedParts[sex][part]
        if (part === 'eye' && eyeDirection !== '')
          facePartUrl = order.savedSelectedParts[sex]['eye_direction'][eyeDirection]
        if (part === 'eyebrows' && eyebrowsType !== '')
          facePartUrl = order.savedSelectedParts[sex]['eyebrows_type'][eyebrowsType]
        if (part === 'lips' && lipsType !== '')
          facePartUrl = order.savedSelectedParts[sex].smile
        //moving face parts
        if (availableMoveFaceParts.includes(part) && order.movedFace[sex][part]) {
          const scale = order.movedFace[sex][part]?.zoom === 1 ? 1 : 1 + (order.movedFace[sex][part]?.zoom || 0)
          const scaledWidth = width * scale
          const scaledHeight = height * scale
          const position = [pos[0], pos[1] + (-order.movedFace[sex][part]?.posY || 0)]
          const diff = [(scaledWidth - width) / 2, (scaledHeight - height) / 2]
          const scaledPos = [position[0] - diff[0], position[1] - diff[1]]
          await draw(context, facePartUrl, scaledWidth * scaleScene[0], scaledHeight * scaleScene[1],
              [scaledPos[0] * scaleScene[0], scaledPos[1] * scaleScene[0]], angle)
        } else {
          await draw(context, facePartUrl, widthDraw, heightDraw, posDraw, angle)
        }
      }
      if (Object.keys(swapDraw).includes(part)) {
        await draw(context, order.savedSwapParts[sex][part][swapDraw[part]], widthDraw, heightDraw, posDraw, angle)
      }
    }
  }
}

export async function draw(context, src, width, height, pos = [0,0], angle = 0, cross = true) {
  if (!src) return

  const url = cross ? await changeColorsSVG({
    url: src,
    name: src,
    type: 'skin',
    skins: {}
  }) : src

  const img = new Image()
  img.src = url

  await img.decode().then(() => {
    context.save()
    context.rotate(angle * Math.PI / 180);
    context.drawImage(img, pos[0], pos[1], width, height)
    context.restore()
  }).catch(e => console.error('Failed', src, url))
}

function Face({id, width, height, pos = [0,0]}) {
  const {order} = useContext(OrderContext)
  const {loaderFaceParts} = useContext(LoadFacePartsContext)

  const canvas = useRef()

  useEffect(() => {
    const context = canvas.current.getContext('2d')
    context.clearRect(pos[0], pos[1], width, height)
    drawFace({context, order, sex: id, width, height, pos})
  }, [loaderFaceParts.redraw[id]])

  return <canvas ref={canvas} id={id} width={width} height={height} className='faceCanvas'/>
}

Face.propTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  pos: PropTypes.arrayOf(PropTypes.number)
};

export default observer(Face)
