import React, { useCallback, useEffect, useRef, useState } from 'react'
import LayoutAdmin from '../../modules/LayoutAdmin/LayoutAdmin'
import { COVERS_ROUTE } from '../../utils/consts'
import { getCovers, saveCover } from '../../API/coverAPI'
import Multiselect from 'multiselect-react-dropdown'
import Input from '../../UI/Input/Input'
import defaultConfig from '../../default-config.json'
import Button from '../../UI/Button/Button'

function Covers() {
  const [topic, setTopic] = useState('all')
  const [coverList, setCoverList] = useState([])
  const [editCover, setEditCover] = useState()

  useEffect(() => {
    getCovers().then(covers => setCoverList(covers))
  }, [])

  return (
    <LayoutAdmin currentPath={COVERS_ROUTE}>
      <h1 className='title'>Covers ({coverList.length})</h1>
      <div className="row">
        <div className="col-75">
          <div className='listCovers'>
            {
              coverList.map(cover => {
                return <CoverEntry key={cover.name} cover={cover} setCover={setEditCover} topic={topic}/>
              })
            }
          </div>
        </div>
        <div className="col-25">
          <CoverForm editCover={editCover} setEditCover={setEditCover} updateCoverList={setCoverList}/>
        </div>
      </div>
    </LayoutAdmin>
  )
}

function CoverEntry({cover, setCover, topic}) {
  const src = process.env.REACT_APP_COVERS_PATH + `${cover.name}.webp`

  const chooseCover = useCallback(() => setCover(cover), [cover, setCover])

  return <div
    className={`card ${cover.topics?.includes(topic) ? 'd-none' : ''}`}
    onClick={chooseCover}
  >
    <span className='previewName'>{cover.name}</span>
    <img src={src} alt="" />
  </div>
}

function CoverForm({editCover, setEditCover, updateCoverList}) {
  const [name, setName] = useState('')
  const [topics, setTopics] = useState([])
  const multiselectTopics = useRef()
  const topicsCover = defaultConfig.topicsCover.reduce((a, v, i) => {a.push({name: v, id: i}); return a}, [])
  const selectedTopics = (editCover) ? topicsCover.filter(topic => (editCover.topics.includes(topic.name))) : []

  useEffect(() => {
    setName(editCover?.name || '')
    setTopics(editCover?.topics || [])
  }, [editCover])

  const changeName = useCallback(e => setName(e.target.value.trim()), [])

  const updateTopics = useCallback(() => {
    setTopics(multiselectTopics.current.getSelectedItems().map(v => v.name))
  }, [])

  const updateCover = useCallback(() => {
    const cover = {
      ...editCover,
      edit: !!editCover,
      name,
      topics
    }
    saveCover(cover).then(res => {
      getCovers().then(covers => updateCoverList(covers))
      setEditCover(null)
    }).catch(err => console.error(err))
  }, [name, topics, editCover])

  return <div>
    <div className="label mt-15">Topics:</div>
    <Multiselect
      id='topics'
      ref={multiselectTopics}
      options={topicsCover}
      selectedValues={selectedTopics}
      onSelect={updateTopics}
      onRemove={updateTopics}
      displayValue="name"
    />
    <Input
      className='filled'
      value={name}
      placeholder="Name"
      onChange={changeName}
    />
    <Button className='mt-15' onClick={updateCover}>Save</Button>
  </div>
}

export default Covers