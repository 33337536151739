import React from 'react'
import './ReviewBlock.scss'
import {useTranslation} from 'react-i18next'

function ReviewBlock() {
  const { t } = useTranslation()

  return <div className='reviewBlock'>
    <div className='innerWidth paddingPage'>
      <h2>{t('Reviews', {ns: 'main'})}</h2>
      <p className="desc">{t('Our client about us', {ns: 'main'})}</p>
      <div className="reviewList">
        <div className="review">
          <h3>{t('Cute and creative gift', {ns: 'main'})}</h3>
          <p className="text">
            {t('Cute and creative gift desc', {ns: 'main'})}
          </p>
          <div className="name">{t('Natali', {ns: 'main'})}</div>
        </div>
        <div className="review">
          <h3>{t('A fun book for friend', {ns: 'main'})}</h3>
          <p className="text">
            {t('A fun book for friend desc', {ns: 'main'})}
          </p>
          <div className="name">{t('Yura', {ns: 'main'})}</div>
        </div>
        <div className="review">
          <h3>{t('Congratulations on the anniversary', {ns: 'main'})}</h3>
          <p className="text">
            {t('Congratulations on the anniversary desc', {ns: 'main'})}
          </p>
          <div className="name">{t('Ann', {ns: 'main'})}</div>
        </div>
      </div>
    </div>
  </div>
}

export default ReviewBlock
