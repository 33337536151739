import React, {useEffect, useState} from 'react'
import LayoutDefault from '../modules/LayoutDefault'
import GreetingView from '../components/Greeting/GreetingView'
import OrderState from '../store/OrderState'
import {OrderContext} from '../utils/shared/Context'
import {getOrder} from '../API/orderAPI'
import {useParams} from 'react-router-dom'
import Loading from '../UI/Loading/Loading'

const Greeting = () => {
  const {id} = useParams()
  const [currentOrder, setCurrentOrder] = useState()

  useEffect(() => {
    getOrder(id, true).then(orderObj => {
      setCurrentOrder(orderObj)
    })
  }, [])

  if (!currentOrder) return null

  return <OrderContext.Provider value={{
    order: new OrderState(currentOrder?.bookSettings)
  }}>
    <LayoutDefault>
      <div className='pageBlock transparent'>
        <div className='innerWidth paddingPage'>
          <div className="contentBlock">
            <div className="flexColumn flexGrow text-center">
              {(currentOrder?.status !== 'done' || currentOrder?.payment.status !== 'done') ?
                  <Loading/> :
                  <GreetingView currentOrder={currentOrder}/>}
            </div>
          </div>
        </div>
      </div>
    </LayoutDefault>
  </OrderContext.Provider>
}

export default Greeting